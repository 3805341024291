<script setup lang="ts">
import type { Option } from "../index";
import { twMerge } from "tailwind-merge";
import { ref } from "vue";

interface Props {
	openingHours: Option[];
	title?: string;
	variant?: boolean;
}

const { title = undefined, variant = false } = defineProps<Props>();

const today = ref<number>(new Date().getDay() - 1);
</script>

<template>
	<div :class="twMerge(['bg-white rounded-lg p-4', ($attrs.class as string) || ''])">
		<h3 v-if="title" class="font-brush pb-4" :class="[variant ? 'text-3xl' : 'text-4xl']">
			{{ title }}
		</h3>
		<slot />
		<div
			class="rounded-lg p-5 flex flex-col gap-3"
			:class="[variant ? 'bg-sand' : 'border-dashed border border-brown']"
		>
			<div
				v-for="(hours, i) in openingHours"
				:key="hours.label"
				class="flex flex-wrap justify-between gap-x-4 text-base leading-7"
				:class="{
					'font-bold': i === today
				}"
			>
				<span>
					{{ hours.label }}
				</span>
				<span>
					{{ hours.value }}
				</span>
			</div>
		</div>
	</div>
</template>
